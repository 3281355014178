export const RIGHTS = {
    ALL: 'app',
    EXPORT: {
        ALL: 'app.export',
    },
    CONSULT: {
        ALL: 'app.consult',
        USERS: 'app.consult.users',
        STATION: 'app.consult.station',
        COMPANY: 'app.consult.company',
        PRODUCT: 'app.consult.product',
        RESERVATION: 'app.consult.reservation',
        TANK: 'app.consult.tank',
        TRUCK: 'app.consult.truck',
    },
    EDIT: {
        ALL: 'app.edit',
        USERS: 'app.edit.users',
        STATION: 'app.edit.station',
        COMPANY: 'app.edit.company',
        PRODUCT: 'app.edit.product',
        RESERVATION: 'app.edit.reservation',
        TANK: 'app.edit.tank',
        TRUCK: 'app.edit.truck',
    },
    CREATE: {
        ALL: 'app.create',
        STATION: 'app.create.station',
        COMPANY: 'app.create.company',
        USERS: 'app.create.users',
        TANK: 'app.create.tank',
        TRUCK: 'app.create.truck',
    },
    DELETE: {
        ALL: 'app.delete',
        RESERVATION: 'app.delete.reservation',
        STATION: 'app.delete.station',
        USERS: 'app.delete.users',
    },
    SEARCH: {
        ALL: 'app.search',
        RESERVATION: 'app.search.reservation',
    },
    CLOSE: {
        ALL: 'app.close',
        RESERVATION: 'app.close.reservation',
    },
};
