import { IReservation } from '../../common/interfaces';
import { BaseEntity, OperationOptions } from './base.entity';
import * as moment from 'moment';
import { Type, plainToClass, classToPlain } from 'class-transformer';

export class Reservation extends BaseEntity implements IReservation {
    tankId: string;
    @Type(() => Date)
    start: moment.Moment;
    @Type(() => Date)
    end: moment.Moment;
    productTypeId?: string;
    truckId?: string;
    volume?: number;
    @Type(() => Date)
    actualArrival?: moment.Moment;
    @Type(() => Date)
    actualDeparture?: moment.Moment;
    actualVolume?: number;
    comment?: string;
    truckNumberPlate?: string;
    truckIdentifier?: string;
    isClosed?:boolean;
    
    static findSlots(body: any, args: OperationOptions = {}): Promise<any> {
        //return this.handleGetRequest('', `${this.buildApiPath()}/search`);
        return BaseEntity.http
            .post(`${this.buildApiPath()}/search`,body)
            .toPromise()
            .then((response: any) => {
                if (BaseEntity.msgDisplayFunction && args.okMsg) BaseEntity.msgDisplayFunction(args.okMsg);
                return response;
            })
            .catch((e: Error) => {
                if (BaseEntity.msgDisplayFunction && args.errorMsg) BaseEntity.msgDisplayFunction(args.errorMsg);
                throw e;
            });
    }

    close(args: OperationOptions = {}): Promise<this>{
        return BaseEntity.http
            .patch(
            `${(<typeof BaseEntity>this.constructor).buildApiPath()}/${this.id}/close`,
            classToPlain(this)
            )
            .toPromise()
            .then((response: any) => {
            const obj = plainToClass(<typeof BaseEntity>this.constructor, response);
            Object.assign(this, obj);
            if (BaseEntity.msgDisplayFunction && args.okMsg) BaseEntity.msgDisplayFunction(args.okMsg);
            return this;
            })
            .catch((e: Error) => {
            if (BaseEntity.msgDisplayFunction && args.errorMsg) BaseEntity.msgDisplayFunction(args.errorMsg);
            throw e;
            });
    }

}