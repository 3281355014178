import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of as ObservableOf, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AuthEvents, AUTH_EVENT } from '../auth.events';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(
    routeAsked: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return combineLatest(
      // Check if user is good roles if data.userIs is specified in the route
      // like { path , component , canActivate: [AuthGuard], data: { userIs: 'admin|manager'} },
      (routeAsked.data && routeAsked.data.userIs) ?
        this.authService.userIs(routeAsked.data.userIs)
        : ObservableOf(true),

        // Check if user has good rights if data.userCan is specified in the route
        // like { path , component , canActivate: [AuthGuard], data: { userCan: 'admin|manager'} },
      (routeAsked.data && routeAsked.data.userCan) ?
        this.authService.userCan(routeAsked.data.userCan)
        : ObservableOf(true),

    ).pipe(
      map(([userIsOk, userCanOk]) => {
        const isAuthenticated = this.authService.isAuthenticated();
        if(!isAuthenticated) {
          AuthEvents.emit(AUTH_EVENT.UNAUTHORIZED);
        }
        else if(!userIsOk || !userCanOk) {
          AuthEvents.emit(AUTH_EVENT.UNAUTHORIZED);
        }
        return isAuthenticated && userIsOk && userCanOk;
      }),
    );
  }
}
