import { ICompany } from '../../common/interfaces';
import { BaseEntity } from './base.entity';
import { Reservation } from './reservation.entity';

export class Company extends BaseEntity implements ICompany {
    name: string;

    static findTrucks(id: number | string, noCache: boolean = false): Promise<any> {
        return this.handleGetRequest(id + 'trucks', `${this.buildApiPath()}/${id}/trucks`, noCache);
    }

    static findReservations(id: number | string) {
        return Reservation.handleGetRequest(id + 'reservations', `${this.buildApiPath()}/${id}/reservations`);
    }
}
