import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/client/entities';
import { IUserService } from '../../auth';
import { BaseService } from '../../model/base.service';

@Injectable()
export class UserService extends BaseService<User> implements IUserService {
    constructor(http: HttpClient) {
        super(User, '/api/user', http);
    }

    deserializeUser(rawUser: any) {
        return rawUser;
    }

    login(email: string, password: string): Promise<string> {
        return this.http
        .post(
            `api/auth/login`,
            {
                email,
                password,
            },
            { responseType: 'text' }
        )
        .toPromise();
    }

    logout(): Promise<void> {
        return this.http.get<void>(`/api/auth/logout`).toPromise();
    }

    me(): Promise<User> {
        return this.http.get<User>(`/api/auth/me`).toPromise();
    }
}