import { EventEmitter } from '@angular/core';

export enum AUTH_EVENT {
    LOGIN = 'login',
    LOGOUT = 'logout',
    UNAUTHORIZED = 'unauthorized',
    FORBIDDEN = 'forbidden',
    ERROR = 'error'
}

export const AuthEvents: EventEmitter<AUTH_EVENT> = new EventEmitter<AUTH_EVENT>();
