import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from '../auth';

export const ROUTES: Routes = [
    {
        path: 'calendar',
        loadChildren: './calendar#CalendarModule',
        canActivate: [ AuthGuardService ],
    },
    {
        path: 'reservation',
        loadChildren: './reservation#ReservationModule',
        canActivate: [ AuthGuardService ],
    },
    {
        path: 'user',
        // loadChildren: () => import('./user').then((mod) => mod.UserModule)
        loadChildren: './user#UserModule',
        canActivate: [ AuthGuardService ],
        data: {
            userCan: 'app.consult.users',
        },
    },
    {
        path: 'company',
        loadChildren: './company#CompanyModule',
        canActivate: [ AuthGuardService ],
    },
    {
        path: 'station',
        loadChildren: './station#StationModule',
        canActivate: [ AuthGuardService ],
    },
    {
        path: 'tank',
        loadChildren: './tank#TankModule',
        canActivate: [ AuthGuardService ],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '**',
        redirectTo: '',
        canActivate: [ AuthGuardService ],
    }
];
