import { HttpClient } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';
import { IEntity } from '../../common/interfaces';

export interface OperationOptions {
  okMsg?: string;
  errorMsg?: string;
}

export class BaseEntity implements IEntity {
  public static http: HttpClient;
  public static server = window.location.origin;
  public static namespace = '/api';
  public static endpoint: string;
  public static readonly cached: boolean = false;
  private static cache: { [id: string]: { [id: string]: any } } = {};
  private static pendingCalls: { [id: string]: { [id: string]: Promise<any> } } = {};
  public static msgDisplayFunction: Function;

  id: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedBy: string;
  deletedAt: Date;

  public static buildApiPath(): string {
    if (!this.endpoint) this.endpoint = '/' + this.name.substr(0, 1).toLowerCase() + this.name.substr(1);
    return `${this.server}${this.namespace}${this.endpoint}`;
  }

  constructor(prop: any = {}) {
    Object.assign(this as any, prop);
  }

  public getId() {
    if (!this.id) throw Error('You might be using a class that does not have "id" as primary key, please implement getId()')
    return this.id;
  };

  save(args: OperationOptions = {}): Promise<this> {
    if (this.id) {
      return BaseEntity.http
        .patch(
          `${(<typeof BaseEntity>this.constructor).buildApiPath()}/${this.id}`,
          classToPlain(this)
        )
        .toPromise()
        .then((response: any) => {
          const obj = plainToClass(<typeof BaseEntity>this.constructor, response);
          Object.assign(this, obj);
          if (BaseEntity.msgDisplayFunction && args.okMsg) BaseEntity.msgDisplayFunction(args.okMsg);
          return this;
        })
        .catch((e: Error) => {
          if (BaseEntity.msgDisplayFunction && args.errorMsg) BaseEntity.msgDisplayFunction(args.errorMsg);
          throw e;
        });
    } else {
      return BaseEntity.http
        .post(`${(<typeof BaseEntity>this.constructor).buildApiPath()}`, classToPlain(this))
        .toPromise()
        .then((response: any) => {
          const obj = plainToClass(<typeof BaseEntity>this.constructor, response);
          Object.assign(this, obj);
          if (BaseEntity.msgDisplayFunction && args.okMsg) BaseEntity.msgDisplayFunction(args.okMsg);
          return this;
        })
        .catch((e: Error) => {
          if (BaseEntity.msgDisplayFunction && args.errorMsg) BaseEntity.msgDisplayFunction(args.errorMsg);
          throw e;
        });
    }
  }
  
  remove(args: OperationOptions = {}): Promise<this> {
    if (this.id) {
      return BaseEntity.http
        .delete(
          `${(<typeof BaseEntity>this.constructor).buildApiPath()}/${this.id}`,
          classToPlain(this)
        )
        .toPromise()
        .then((response: any) => {
          const obj = plainToClass(<typeof BaseEntity>this.constructor, response);
          Object.assign(this, obj);
          if (BaseEntity.msgDisplayFunction && args.okMsg) BaseEntity.msgDisplayFunction(args.okMsg);
          return this;
        })
        .catch((e: Error) => {
          if (BaseEntity.msgDisplayFunction && args.errorMsg) BaseEntity.msgDisplayFunction(args.errorMsg);
          throw e;
        });
    }else{
      throw new Error(`L'utilisateur spécifié n'a pas d'id. `);
    } 
    
  }

  static handleGetRequest(key: string, path: string, noCache: boolean = false) {
    if (!this.cache[this.name]) {
      this.invalidateCache();
    }
    if (this.cache[this.name][key] && !noCache) {
      return Promise.resolve(this.cache[this.name][key]);
    }
    if (this.pendingCalls[this.name][key]) {
      return this.pendingCalls[this.name][key];
    }
    this.pendingCalls[this.name][key] = BaseEntity.http
      .get(path)
      .toPromise()
      .then((response: any) => {
        const obj = plainToClass(this, response);
        if (this.cached) {
          this.cache[this.name][key] = obj;
        }
        delete this.pendingCalls[this.name][key];
        return obj;
      })
      .catch(e => {
        delete this.pendingCalls[this.name][key];
        throw e;
      });

    return this.pendingCalls[this.name][key];
  }


  static patch(id: number | string, props: any) {
    return BaseEntity.http
      .patch(`${this.buildApiPath()}/${id}`, props)
      .toPromise();
  }

  static find(noCache: boolean = false): Promise<any> {
    // return this.handleGetRequest('', 'http://localhost:1337/user');
    return this.handleGetRequest('', `${this.buildApiPath()}`, noCache);
  }

  static findOne(id: number | string, noCache: boolean = false): Promise<any> {
    return this.handleGetRequest(id + '', `${this.buildApiPath()}/${id}`, noCache);
  }

  static invalidateCache(id?: string | number) {
    if (id) {
      delete this.pendingCalls[this.name][id];
      delete this.cache[this.name][id];
    } else {
      this.pendingCalls[this.name] = {};
      this.cache[this.name] = {};
    }
  }
}
