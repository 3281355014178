import {
    EventEmitter,
    ModuleWithProviders,
    NgModule, Optional, SkipSelf
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebStorageModule} from 'ngx-store';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {AuthService, TokenService, AuthGuardService} from './services';
import {UserService} from './services/user.service';
import {UserCanDirective, UserIsDirective} from './directives';

export function jwtOptionsFactory(tokenService) {
    return {
        tokenGetter: () => {
            return tokenService.token;
        }
    };
}

@NgModule({
    imports: [
        CommonModule,
        WebStorageModule,
        JwtModule.forRoot({
            config: {
                whitelistedDomains: ['/api']
            },
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        })
    ],
    providers: [
        AuthGuardService,
        AuthService,
        TokenService,
    ],
})
export class AuthModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('AuthModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(AppUserService: any): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: UserService, useExisting: AppUserService}
                // {provide: UserServiceConfig, useValue: config}
            ]
        };
    }
}
