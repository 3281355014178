import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserCanDirective, UserIsDirective} from './directives';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        UserCanDirective,
        UserIsDirective,
    ],
    exports: [
        UserCanDirective,
        UserIsDirective,
    ],
})
export class AuthUtilsModule {}
