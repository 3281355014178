import { Injectable, Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of as ObservableOf, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { get as lodashGet, uniq as lodashUniq } from 'lodash';

import { ROLES } from '../../shared/common/src';
import { TokenService } from './token.service';
import { UserService } from './user.service';
import { AUTH_EVENT, AuthEvents } from '../auth.events';

@Injectable()
export class AuthService {

    constructor(
        private jwtHelper: JwtHelperService,
        private tokenService: TokenService,
        private userService: UserService
    ) {
    }

    public get user(): any {
        return this.userService.deserializeUser(this.jwtHelper.decodeToken(this.tokenService.token));
    }
    private API_PATH = '/api/auth';

    public currentUser$: ReplaySubject<any> = new ReplaySubject(1);

    public currentUserRights$: Observable<string[]> = this.currentUser$.pipe(
        map((user) => {
            if (!user) {
                return [];
            }

            const userRoles = lodashGet(user, 'roles', []) as string[];

            return userRoles.reduce((rights, role) => {
                return lodashUniq(rights.concat(lodashGet(ROLES, [role, 'rights'], [])));
            }, []);
        }),
        shareReplay(1),
    );

    public init() {
        if (this.isAuthenticated()) {
            this.loadUser()
                .then((user: any) => {
                    AuthEvents.emit(AUTH_EVENT.LOGIN);
                })
                .catch((err: any) => {
                    AuthEvents.emit(AUTH_EVENT.UNAUTHORIZED);
                })
                ;
        } else {
            AuthEvents.emit(AUTH_EVENT.UNAUTHORIZED);
        }
    }

    public isAuthenticated(): boolean {
        const token = this.tokenService.token;
        return !this.jwtHelper.isTokenExpired(token);
    }


    public loadUser(): Promise<any> {
        return this.userService.me()
            .then((user) => {
                this.currentUser$.next(user);
                return user;
            })
            .catch((err) => {
                this.currentUser$.next(null);
                throw err;
            });
    }

    async login(email: string, password: string): Promise<boolean> {
        try {
            const token = await this.userService
                .login(email, password);

            this.tokenService.token = token;
            await this.loadUser();
            AuthEvents.emit(AUTH_EVENT.LOGIN);
            return true;
        } catch (err) {
            if (err instanceof HttpErrorResponse && err.status === 401) {
                AuthEvents.emit(AUTH_EVENT.UNAUTHORIZED);
                console.error('Login failed');
                return false;
            }
            AuthEvents.emit(AUTH_EVENT.ERROR);
            console.error('Fatal error', err.message);
            return false;
        }
        /*this.http
          .post(`${this.API_PATH}/login`, {
            login: username,
            password: password
          }, {responseType: 'text'})
          .subscribe((token: any) => {
            this.tokenService.token = token;
            //this.router.navigateByUrl('/');
            this.events.emit(AUTH_EVENT.LOGIN);
          }, (err: any) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              this.events.emit(AUTH_EVENT.UNAUTHORIZED);
              return console.error('Login failed');
            }
            this.events.emit(AUTH_EVENT.ERROR);
            console.error('Fatal error', err.message);
          });*/
    }

    async logout(): Promise<void> {
        // const headers = new HttpHeaders().set('Authorization', `Bearer ${this.tokenService.token}`);
        await this.userService.logout();
        this.currentUser$.next(null);
        AuthEvents.emit(AUTH_EVENT.LOGOUT);
        this.tokenService.token = null;
    }

    async googleAuth(): Promise<boolean> {
        const googleUrl = `${this.API_PATH}/google`;

        return new Observable((observer) => {
            let gotRes = false;
            function onPopupMessage(event) {
                gotRes = true;
                observer.next(event.data);
            }
            window.addEventListener('message', onPopupMessage);
            // Open the popup
            const popup = popupCenter(googleUrl, 'Google authentification', 400, 600);
            const checkCloseInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(checkCloseInterval);
                    window.removeEventListener('message', onPopupMessage, false);
                    if (!gotRes) {
                        observer.error(new Error('No data received for Google auth'));
                    }
                    observer.complete();
                }
            }, 500);
        }).toPromise()
            .then(async (token) => {
                this.tokenService.token = token.toString();
                await this.loadUser();
                AuthEvents.emit(AUTH_EVENT.LOGIN);
                return true;
            })
            .catch((err) => {
                AuthEvents.emit(AUTH_EVENT.ERROR);
                return false;
            });
    }


    // role can have & or | to separate multiple roles. & has priority.
    public userIs(roles: string): Observable<boolean> {
        if (!roles) {
            return ObservableOf(true);
        }

        const rolesToCheck = this.parseRightsToCheck(roles);

        return this.currentUser$.pipe(
            map((user) => {
                const userRoles = lodashGet(user, 'roles', []);
                return this.checkListRights(userRoles, rolesToCheck);
            }),
        );
    }

    // right can have & or | to separate multiple rights. & has priority.
    public userCan(rights: string): Observable<boolean> {
        const rightsToCheck = this.parseRightsToCheck(rights);

        return this.currentUserRights$.pipe(
            map((userRights) => {
                return this.checkListRights(userRights, rightsToCheck);
            }),
        );
    }


    // Create an array (for or) of array (for and)
    // ex: 'a|b&c' => [[a], [b, c]] => a or b and c
    private parseRightsToCheck(rights: string): string[][] {
        // Split or operands
        return rights.split('|')
            .map((right) => right.trim()).filter((right) => right.length)

            .map((right) => {
                // Split and operands
                return lodashUniq(
                    right.split('&')
                        .map((r) => r.trim()).filter((r) => r.length)
                );
            });
    }

    private checkListRights(rights: string[], rightsToCheck: string[][]): boolean {
        return rights.length
            && rightsToCheck.some((rightsAnd) => {
                return this._checkAllRightsIn(rights, rightsAnd);
            });
    }

    private _checkAllRightsIn(rights: string[], rightsToCheck: string[]): boolean {
        return rightsToCheck.every((rightToCheck) => {
            return rights.some((right) => rightToCheck.startsWith(right));
        });
    }
}

// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
function popupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width
    ;
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height
    ;

    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
    return newWindow;
}
