import { IEvent, ICalendar } from '../../common/interfaces';

export class Event implements IEvent {

    public title: string;
    public startDate: string;
    public endDate: string;
    public description: string;
    public location: string;
    public CONFIG: Array<any> = [];

    constructor(data) {
        this.title = data.title;
        this.description = "Camion : " + data.truck + ", Volume : " + data.volume + "m³";
        this.location = data.location;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.CONFIG = [
            {
                key: 'BEGIN',
                value: 'VEVENT',
            },
            {
                key: 'DTSTART;TZID=Europe/Paris',
                value: this.startDate,
            },
            {
                key: 'DTEND;TZID=Europe/Paris',
                value: this.endDate,
            },
            {
                key: 'SUMMARY',
                value: this.title,
            },
            {
                key: 'DESCRIPTION',
                value: this.description,
            },
            {
                key: 'LOCATION',
                value: this.location,
            },
            {
                key: 'BEGIN',
                value: 'VALARM',
            },
            {
                key: 'ACTION',
                value: 'DISPLAY',
            },
            {
                key: 'TRIGGER',
                value: '-P1D',
            },
            {
                key: 'END',
                value: 'VALARM',
            },
            {
                key: 'END',
                value: 'VEVENT',
            },
        ];
    }
}

export class Calendar implements ICalendar {
    public static BASE_CONFIG: Array<any> = [
        {
            key: 'BEGIN',
            value: 'VCALENDAR',
        },
        {
            key: 'VERSION',
            value: '2.0',
        },
        {
            key: 'PRODID',
            value: '-//Calendrier//Réservations//EN',
        },
        {
            key: 'CALSCALE',
            value: 'GREGORIAN',
        },
        {
            key: 'BEGIN',
            value: 'VTIMEZONE',
        },
        {
            key: 'TZID',
            value: 'Europe/Paris',
        },
        {
            key: 'TZURL',
            value: 'http://tzurl.org/zoneinfo-outlook/Europe/Paris',
        },
        {
            key: 'X-LIC-LOCATION',
            value: 'Europe/Paris',
        },
        {
            key: 'BEGIN',
            value: 'DAYLIGHT',
        },
        {
            key: 'TZOFFSETFROM',
            value: '+0100',
        },
        {
            key: 'TZOFFSETTO',
            value: '+0200',
        },
        {
            key: 'TZNAME',
            value: 'CEST',
        },
        {
            key: 'DTSTART',
            value: '19700329T020000',
        },
        {
            key: 'RRULE',
            value: 'FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU',
        },
        {
            key: 'END',
            value: 'DAYLIGHT',
        },
        {
            key: 'BEGIN',
            value: 'STANDARD',
        },
        {
            key: 'TZOFFSETFROM',
            value: '+0200',
        },
        {
            key: 'TZOFFSETTO',
            value: '+0100',
        },
        {
            key: 'TZNAME',
            value: 'CET',
        },
        {
            key: 'DTSTART',
            value: '19701025T030000',
        },
        {
            key: 'RRULE',
            value: 'FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU',
        },
        {
            key: 'END',
            value: 'STANDARD',
        },
        {
            key: 'END',
            value: 'VTIMEZONE',
        },
    ];

    public baseConf: any = Calendar.BASE_CONFIG;
    public events: Event[] = [];

    constructor(Events) {
        Events.forEach((event) => {
            this.events.push(event);
        });
    }

    public exportAsString(): string {
        let output: string = '';
        this.baseConf.forEach((element) => {
            output += element.key + ':' + element.value + '\n';
        });
        this.events.forEach((event) => {
            event.CONFIG.forEach((e) => {
                output += e.key + ':' + e.value + '\n';
            })
        })
        output += "END:VCALENDAR";
        return output;
    }

}