import { IUser } from '../../common/interfaces';
import { BaseEntity } from './base.entity';
import { IsString, IsEmail, IsOptional } from 'class-validator';
import { get as lodashGet, uniq as lodashUniq } from 'lodash';

export class User extends BaseEntity implements IUser {
    constructor(prop: any = {}) {
        super();
        Object.assign(this as any, prop);
        this.roles = this.roles || [];
    }

    @IsString()
    // @ApiModelProperty({ description: 'Firstname' })
    firstName: string;

    @IsString()
    // @ApiModelProperty({ description: 'Lastname' })
    lastName: string;

    @IsEmail()
    // @ApiModelProperty({ description: 'e-mail (unique)' })
    email: string;

    @IsString()
    @IsOptional()
    // @ApiModelProperty()
    password: string;

    @IsString({each: true})
    roles: string[];

    @IsString()
    companyId: string;

    @IsString()
    companyName: string;

    public is(roles: string): boolean {
        if (!roles) {
            return true;
        }
        const rolesToCheck = this.parseRightsToCheck(roles);
        return this.checkListRights(this.roles, rolesToCheck);

    }

    // Create an array (for or) of array (for and)
    // ex: 'a|b&c' => [[a], [b, c]] => a or b and c
    private parseRightsToCheck (rights: string): string[][] {
        // Split or operands
      return rights.split('|')
        .map((right) => right.trim()).filter((right) => right.length)

      .map((right) => {
        // Split and operands
        return lodashUniq(
          right.split('&')
            .map((right) => right.trim()).filter((right) => right.length)
        );
      });
    }

    private checkListRights(rights: string[], rightsToCheck: string[][]): boolean {
        return rights.length
          && rightsToCheck.some((rightsAnd) => {
            return this._checkAllRightsIn(rights, rightsAnd);
          });
      }

      private _checkAllRightsIn(rights: string[], rightsToCheck: string[]): boolean {
        return rightsToCheck.every((rightToCheck) => {
            return rights.some((right) => rightToCheck.startsWith(right));
        });
      }

}
