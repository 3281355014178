import { ITruck } from '../../common/interfaces';
import { BaseEntity } from './base.entity';

export class Truck extends BaseEntity implements ITruck {
    numberPlate: string;    
    identifier: string;
    maxCapacity: number;
    currentLoad: number;
    productTypeCode: string;
    companyId: string;
}