import { RIGHTS } from './rights';

export const ROLES = {
    kyrnolia: {
        label: 'Administration Kyrnolia',
        rights: [
            RIGHTS.ALL,
        ],
    },
    admin_step: {
        label: 'Administration STEP',
        rights: [
            RIGHTS.EXPORT.ALL,
            RIGHTS.CONSULT.ALL,
            RIGHTS.EDIT.RESERVATION,
            RIGHTS.EDIT.STATION,
            RIGHTS.EDIT.TANK,
            RIGHTS.EDIT.TRUCK,
            RIGHTS.SEARCH.ALL,
            RIGHTS.CLOSE.ALL,
            RIGHTS.SEARCH.RESERVATION,
            RIGHTS.CREATE.COMPANY,
            RIGHTS.CREATE.TRUCK,
            RIGHTS.CREATE.TANK,
        ],
    },
    lgce: {
        label: 'LGCE',
        rights: [
            RIGHTS.ALL,
        ],
    },
    client: {
        label: 'Sous-traitant',
        rights: [
            RIGHTS.CONSULT.COMPANY,
            RIGHTS.CONSULT.TANK,
            RIGHTS.CONSULT.PRODUCT,
            RIGHTS.CONSULT.RESERVATION,
            RIGHTS.CONSULT.STATION,
            RIGHTS.CONSULT.TRUCK,

            RIGHTS.EDIT.RESERVATION,
            RIGHTS.EDIT.TRUCK,

            RIGHTS.DELETE.RESERVATION,
            RIGHTS.SEARCH.RESERVATION, 
            // RIGHTS.CONSULT.USERS
        ],
    },
};
