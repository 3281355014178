import { IUserService } from '../interfaces';

export class UserService implements IUserService {
    constructor() {
        throw new Error('I should be overridden by app module');
    }

    login(username: string, password: string): Promise<string> {
        throw new Error('Unimplemented yet');
    }

    logout(): Promise<void> {
        throw new Error('Unimplemented yet');
    }

    me(): Promise<any> {
        throw new Error('Unimplemented yet');
    }

    deserializeUser(rawUser: any): any {
        throw new Error('Unimplemented yet');
    }
}
