import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { omit } from 'lodash';
import { plainToClass } from 'class-transformer';

export class BaseService<E> {
    protected APIROOT: string;
    protected http: HttpClient;
    protected instance: any;
    public excludedKeys: string[] = ['id', 'createdAt', 'updatedAt'];

    constructor(instance: any, apiRoot: string, http: HttpClient) {
        this.instance = instance;
        this.http = http;
        this.APIROOT = apiRoot;
    }

    public async find(params?: any): Promise<E[]> {

        const items = await this.http
            .get<Object[]>(`${this.APIROOT}/all`, {params: params})
            .toPromise()
            .then(
                (res: Object[]) => plainToClass(this.instance, res as Object[]) as E[]
            )
        ;

        return items;
    }

    public async get(id: string): Promise<E> {
        return this.http
            .get<Object>(`${this.APIROOT}/${id}`)
            .toPromise()
            .then((res: Object) => plainToClass(this.instance, res as Object) as E)
        ;

    }

    public async create(data: E): Promise<E> {
        return this.http
            .post(`${this.APIROOT}`, omit(data as any, this.excludedKeys))
            .toPromise()
            .then((res: Object) => plainToClass(this.instance, res as Object) as E)
        ;
    }

    public async createMany(data: E[]): Promise<E[]> {
        if (!Array.isArray(data)) {
            data = [data];
        }
        return this.http
            .post(`${this.APIROOT}`, data.map((d) => omit(d as any, this.excludedKeys)))
            .toPromise()
            .then((res: any) => {
                if (!Array.isArray(res)) {
                    res = [res];
                }
                return res.map((r) => plainToClass(this.instance, r as Object) as E);
            })
        ;
    }

    public async update(id: string, data: E): Promise<E> {
        return this.http
            .put(`${this.APIROOT}/${id}`, omit(data as any, this.excludedKeys))
            .toPromise()
            .then((res: Object) => plainToClass(this.instance, res as Object) as E)
        ;
    }

    public async remove(id: string): Promise<any> {
        return this.http
            .delete(`${this.APIROOT}/${id}`)
            .toPromise()
        ;
    }
}
