import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';

import { AuthService } from '../services/auth.service';

import { Subscription } from 'rxjs';

@Directive({ selector: '[userCan]' })
export class UserCanDirective implements OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  private userCanSubscription: Subscription;

  private _currentState: boolean = false;
  private set currentState(state: boolean) {
    if (this._currentState === state) return;

    this._currentState = state;
    if (this._currentState) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
     // Else remove template from DOM
      this.viewContainer.clear();
    }
  }


  @Input() set userCan(rights: string) {
    if (this.userCanSubscription) {
      this.userCanSubscription.unsubscribe();
    }

    this.userCanSubscription = this.authService.userCan(rights)
      .subscribe((allowed) => {
        this.currentState = allowed;
      }, () => {
        // If error, not allowed
        this.currentState = false;
      });
  }

  ngOnDestroy() {
    if (this.userCanSubscription) {
      this.userCanSubscription.unsubscribe();
    }
  }

}
