import { IStation, IStationTimes } from '../../common/interfaces';
import { BaseEntity } from './base.entity';
import { Reservation } from './reservation.entity';
import { Geometry } from 'geojson';

export class Station extends BaseEntity implements IStation {
    name: string;
    address: string;
    town: string;
    geometry: Geometry;
    times: IStationTimes;

    static findTanks(id: number | string, noCache: boolean = false): Promise<any> {
        return this.handleGetRequest(id + 'tanks', `${this.buildApiPath()}/${id}/tanks`, noCache);
    }

    static findReservations(id: number | string, noCache: boolean = false): Promise<Reservation[]>{
        return Reservation.handleGetRequest(id+'reservations', `${this.buildApiPath()}/${id}/reservations`, noCache);
    }
}