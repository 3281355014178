import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-store';

@Injectable()
export class TokenService {

  public set token(value: string) {
    this._token = value;
  }

  public get token(): string {
    return this._token;
  }

  @LocalStorage()
  private _token: string = null;

  constructor() {
  }

}
