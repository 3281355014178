import { ITank } from '../../common/interfaces';
import { BaseEntity } from './base.entity';
import { ProductType } from './product-type.entity';

export class Tank extends BaseEntity implements ITank {
    code: string;
    stationId: string;
    productTypes: ProductType[];    
    maxCapacity: number;
    currentLoad: number;
    volume?: number;
    reservedVolume?: number;

    // static findProducts(id: number | string, noCache: boolean = false): Promise<any> {
    //     return this.handleGetRequest(id + 'products', `${this.buildApiPath()}/${id}/products`, noCache);
    // }

    static findVolume(id: number | string, date: string, noCache: boolean = false): Promise<any> {
        return this.handleGetRequest(id + 'volume', 
                `${this.buildApiPath()}/${id}/volume\?date=${date}`, noCache);
    }
    
    static findReservedVolume(id: number | string, date: string, noCache: boolean = false): Promise<any> {
        return this.handleGetRequest(id + 'reservedVolume', 
                `${this.buildApiPath()}/${id}/reserved-volume\?date=${date}`,noCache);
    }

}