import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, InjectionToken } from '@angular/core';
import { SnotifyModule, SnotifyService, ToastDefaults  } from 'ng-snotify';

import { AppComponent } from './app.component';

import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';


import { ROUTES } from './app.routes';

import { BaseEntity } from '../shared/client/entities';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AuthModule, AuthInterceptor, AuthEvents, AUTH_EVENT, AuthService, AuthUtilsModule } from '../auth';
import { JwtHelperService, JwtModule} from '@auth0/angular-jwt';
import { DataModule, UserService } from '../model';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

const MY_TOAST_CONFIG = ToastDefaults;
MY_TOAST_CONFIG.toast.titleMaxLength = 24;
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // AppRoutingModule,
    RouterModule.forRoot(ROUTES),
    AuthModule.forRoot(UserService),
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    SnotifyModule,
    JwtModule,
    DataModule,
  ],
  providers: [
    {
      provide: 'SnotifyToastConfig',
      useValue: MY_TOAST_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    SnotifyService,
    JwtHelperService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    http: HttpClient,
    router: Router,
    route: ActivatedRoute,
    authService: AuthService,
    snotifyService: SnotifyService,
  ) {
    BaseEntity.http = http as any;
    AuthEvents.subscribe((event: AUTH_EVENT) => {
      switch (event) {
          case AUTH_EVENT.UNAUTHORIZED:
              if (authService.isAuthenticated()) {
                router.navigate(['/home']);
                snotifyService.warning('Accès non autorisé');
                return;
              }
          case AUTH_EVENT.LOGOUT:
              if (!window.location.pathname.startsWith('/login')) {
                router.navigate(['/login'], {
                });
              }
              return;
          case AUTH_EVENT.LOGIN:
              if (window.location.pathname.startsWith('/login')) {
                router.navigateByUrl('/');
              }
              if(authService.userIs('lgce')){
              }
              return;
          default:
              console.error('Unhandled auth event : ', event);
      }
  });
    authService.init();
}
}
