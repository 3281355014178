import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public username = ''; // = 'seth@wheeler.com';
    public password = ''; // = 'wheeler';

    public loading: boolean = false;
    public errorMessage: string = null;
    public googleUrl: string;

    constructor(
      private authService: AuthService,
    ) {}

    ngOnInit() {
    }

    public login() {
        this.loading = true;
        this.authService
            .login(this.username, this.password)
            .then((logged) => {
              this.loading = false;
              if(!logged) {
                this.errorMessage = 'Echec de l\'authentification';
              }
            });
        ;
    }

    public googleAuth() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.authService.googleAuth()
      .then((logged) => {
        this.loading = false;
        if(!logged) {
          this.errorMessage = 'Echec de l\'authentification';
        }
      });
    }

}
