import { JWT_OPTIONS, JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';
import { Injectable, Inject } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class AuthInterceptor extends JwtInterceptor {
    constructor(
        @Inject(JWT_OPTIONS) config: any,
        public jwtHelper: JwtHelperService
    ) {
        super(config, jwtHelper);
    }

    isWhitelistedDomain(request: HttpRequest<any>): boolean {
        return true;
    }
}
